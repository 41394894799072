'use-client';
import * as React from 'react';
import { replaceCompIdPlaceholderInSvgContent } from '@wix/editor-elements-common-utils';
import VectorImage from '@wix/editor-elements-library/src/components/VectorImage/viewer/VectorImage';
import type { VideoBoxPlayProps } from '../../VideoBox/VideoBox.types';
import styles from '../../VideoBox/viewer/style/VideoBox.scss';

const VideoBoxPlay: React.FC<VideoBoxPlayProps> = ({
  id,
  vectorImageProps,
  shouldRender,
  reducedMotion = false,
  className,
  isClassNameToRootEnabled,
}) => {
  replaceCompIdPlaceholderInSvgContent(vectorImageProps, id);

  const rootClassName = isClassNameToRootEnabled ? className : '';

  return (
    <div id={id} className={rootClassName}>
      {(shouldRender || reducedMotion) && (
        <div>
          <div className={styles.videoboxPause}>
            <svg
              width="60"
              height="60"
              viewBox="0 0 60 60"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="15"
                y="10"
                width="10"
                height="40"
                data-color="1"
                fill="white"
              />
              <rect
                x="35"
                y="10"
                width="10"
                height="40"
                data-color="1"
                fill="white"
              />
            </svg>
          </div>
          <VectorImage
            id={`play-${id}`}
            className={styles.videoboxPlay}
            {...vectorImageProps}
          />
        </div>
      )}
    </div>
  );
};

export default VideoBoxPlay;
